import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DisplayBlogPosts from '../../Blog/DisplayBlogPosts';
import Tabs from '../Tabs';
import './splitlayout.scss';
import layoutConfig from '../../../Routes/layoutConfig';

const TABS = [
  { label: 'Learn', value: 'Learn', route: '/learn' },
  { label: 'Report Scam', value: 'ReportScam', route: '/report-scam' },
  { label: 'Reports', value: 'Reports', route: '/reports' },
  { label: 'Leader Board', value: 'LeaderBoard', route: '/leader-board' },
];

const COMPONENTS = {
  Home: <DisplayBlogPosts />,
};

const SplitLayout = ({
  children = null,
  activeTab = 'Learn',
  showType = '',
}) => {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(activeTab);

  useEffect(() => {
    if (showType.includes('modal') || showType.includes('home')) {
      setCurrentTab('Home');
    } else {
      setCurrentTab(activeTab);
    }
  }, [location, activeTab]);

  return (
    <>
      <div
        className={
          layoutConfig.hideTabs.includes(location.pathname)
            ? 'w3-hide-small'
            : ''
        }
      >
        <Tabs
          tabs={TABS}
          activeTab={currentTab === 'Home' ? 'Learn' : currentTab}
          onTabChange={setCurrentTab}
        />
      </div>
      {(TABS.some(({ value }) => value === currentTab) ||
        currentTab === 'Home') &&
        COMPONENTS[currentTab]}
      {children}
    </>
  );
};

export default SplitLayout;
