import React, { useState, useEffect } from 'react';
import './blog-post.scss';
import moment from 'moment';
import ProfilePicture from '../ProfilePicture';
import featuredImageSVG from '../../../images/icons/scamyodha_app_icon.svg';

const BlogPostCard = ({ item, index }) => {
  const {
    title,
    excerpt,
    link,
    featuredImageUrl,
    authorName,
    authorAvatarUrl,
    date,
    categoryName,
  } = item;

  // Fallback values
  const fallbackAuthorName = authorName || 'Anonymous';
  const fallbackAvatarUrl = authorAvatarUrl || '';
  // const fallbackCategory = categoryName || 'Uncategorized';

  const [imgSrc, setImgSrc] = useState(featuredImageUrl);

  useEffect(() => {
    setImgSrc(featuredImageUrl);
  }, [featuredImageUrl]);

  const handleError = () => {
    setImgSrc(featuredImageSVG);
  };

  return (
    <div className="w3-container w3-white w3-round w3-padding blog-main-container">
      {/* Blog Post Link Section */}
      <a
        className="fab-nav-link"
        href={link.replace('scamyodha.blog.ceegees.in', 'blog.scamyodha.com')}
        target="_blank"
        rel="noreferrer"
      >
        <div className="w3-row w3-margin-bottom">
          {/* Text Section */}
          <div className="w3-col m8 l9">
            <h5>{title}</h5>
            <div
              className="blog-description w3-margin-right"
              dangerouslySetInnerHTML={{ __html: excerpt }}
            />
          </div>

          {/* Featured Image Section */}
          <div className="w3-col m4 l3">
            <div className="featured-img-wrapper">
              <img
                src={imgSrc} // Use state to manage image source
                alt={title} // Using title as alt text for the image
                className="w3-image w3-round"
                loading="lazy"
                onError={handleError} // Trigger the error handler if the image fails to load
              />
            </div>
          </div>
        </div>
      </a>

      {/* Author and Date Section */}
      <div className="flex-container align-items-center justify-content-start">
        {/* Conditionally render the avatar image or ProfilePicture */}
        {fallbackAvatarUrl ? (
          <img
            src={fallbackAvatarUrl}
            alt="Author Avatar"
            className="w3-image w3-circle w3-margin-right"
            loading="lazy"
          />
        ) : (
          <ProfilePicture firstName={fallbackAuthorName} size={36} />
        )}

        <h6 className="w3-margin-right">{fallbackAuthorName}</h6>
        <span className="w3-opacity w3-small">{moment(date).format('ll')}</span>

        {/* Category Tag */}
        <div
          className="w3-round w3-padding-tiny blue-color"
          style={{ marginLeft: 'auto', cursor: 'pointer' }}
        >
          {item.totalCount} Reports
        </div>
      </div>
    </div>
  );
};

export default BlogPostCard;
