import React, { useState, useEffect, useMemo, useRef } from 'react';
import './scam.scss';
import PhoneNumberInput from '../common/PhoneNumberInput';
import TextInput from '../common/TextInput';
import Button from '../common/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllScamArticles, reportScammer } from '../../js/redux/actions';
import {
  acceptOnlyNumbersInput,
  clearFormFieldErrors,
  isValidPhoneNumber,
  isValidUpiId,
} from '../../js/utils';
import ErrorText from '../common/ErrorText';
import { toast } from 'react-toastify';
import FileUploadIcon from '../../images/icons/upload.svg';
import AudioFileIcon from '../../images/icons/Audio.svg';
import VideoFileIcon from '../../images/icons/Video.svg';
import ImageFileIcon from '../../images/icons/Image.svg';
import PdfFileIcon from '../../images/icons/pdf.svg';
import CloseBtnIcon from '../../images/icons/close_btn.svg';
import WarningIcon from '../../images/icons/warning.svg';
import Dropdown from '../common/Dropdown';
import SearchableDropdown from '../common/SearchableDropdown';
import { SELECT_TYPE_OPTIONS } from '../../js/constants';
import Loader from '../common/Loader';
import BlogPostCard from '../common/BlogPostCard';
import useFetchWithControl from '../hooks/useFetchWithControl';

const Report = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const initialFormData = {
    proof: '',
    type: location?.state?.type,
    phoneNo: '',
    upi: '',
    description: '',
    sms: '',
    website: '',
  };

  const [form, setForm] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [disabledField, setDisabledField] = useState(false);
  const [dropDownDisabled, setDropDownDisabled] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const allScamArticles = useSelector((state) => state.allScamArticles);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedScamArticle, setSelectedScamArticle] = useState(null);

  const shouldFetch = useMemo(() => {
    const { data, lastFetched } = allScamArticles ?? {};
    if (!data || data.length === 0) return true;
    if (lastFetched) {
      const timeSinceLastFetch = Date.now() - new Date(lastFetched).getTime();
      return timeSinceLastFetch > 60000; // 60 seconds threshold
    }
    return false;
  }, [allScamArticles?.data, allScamArticles?.lastFetched]);

  useFetchWithControl({
    fetchFn: () => dispatch(getAllScamArticles()),
    shouldFetch,
    interval: 60000,
  });

  const articleTitleOptions = useMemo(() => {
    if (!allScamArticles?.data || allScamArticles.isFetching) return [];

    return allScamArticles.data
      .slice()
      .sort((a, b) => (b.trendingScore ?? 0) - (a.trendingScore ?? 0))
      .map(({ slug, title }) => ({ key: slug, value: title }));
  }, [allScamArticles?.data, allScamArticles?.isFetching]);

  useEffect(() => {
    const { type, uniqueId } = location?.state || {};

    if (!type && !uniqueId) {
      setForm((prevForm) => ({
        ...prevForm,
        type: 'number',
      }));
      setSelectedOption(SELECT_TYPE_OPTIONS[0]);
    } else {
      setDropDownDisabled(true);
      setDisabledField(type !== 'sms');

      const updatedForm = {
        type,
        phoneNo: '',
        upi: '',
        description: '',
        website: '',
      };
      let selectedOptionIndex = 0;

      switch (type) {
        case 'number':
          updatedForm.phoneNo = uniqueId || '';
          selectedOptionIndex = 0;
          break;
        case 'upi':
          updatedForm.upi = uniqueId || '';
          selectedOptionIndex = 1;
          break;
        case 'sms':
          updatedForm.description = uniqueId || '';
          selectedOptionIndex = 2;
          break;
        case 'website':
          updatedForm.website = uniqueId || '';
          selectedOptionIndex = 3;
          break;
        default:
          break;
      }

      setForm((prevForm) => ({
        ...prevForm,
        ...updatedForm,
      }));
      setSelectedOption(SELECT_TYPE_OPTIONS[selectedOptionIndex]);
    }
  }, [location?.state]);

  const handlePhoneNoChange = (value) => {
    if (value === '' || acceptOnlyNumbersInput(value)) {
      setErrors(clearFormFieldErrors('phoneNo', errors));
      setForm({
        ...form,
        phoneNo: value,
      });
    }
  };

  const handleChange = (key, value) => {
    setForm({
      ...form,
      [key]: value,
    });
    setErrors({
      ...errors,
      [key]: '',
    });
  };

  const handleSelectedOption = (val) => {
    setSelectedOption(val);
    setForm({
      ...form,
      type: val.key,
    });
  };

  const handleArticleTitleOption = (val) => {
    setSelectedArticle(val);
    setSelectedScamArticle(
      allScamArticles.data.filter((item) => item.slug === val.key),
    );
    setForm({
      ...form,
      articleSlug: val.key,
    });
  };

  const getFileTypeIcon = () => {
    if (!form.proof) return;
    const fileType = form?.proof?.type.split('/');
    let fileTypeIcon = FileUploadIcon;
    if (fileType[0] === 'image') {
      fileTypeIcon = ImageFileIcon;
    } else if (fileType[0] === 'audio') {
      fileTypeIcon = AudioFileIcon;
    } else if (fileType[0] === 'video') {
      fileTypeIcon = VideoFileIcon;
    } else if (fileType[0] === 'application' && fileType[1] === 'pdf') {
      fileTypeIcon = PdfFileIcon;
    }
    return fileTypeIcon;
  };

  const handleFileUpload = (event) => {
    setForm({
      ...form,
      proof: event.target.files[0],
    });
    setErrors({
      ...errors,
      proof: '',
    });
  };

  const validateSaveData = () => {
    let hasError = false;
    const err = Object.assign({}, errors);
    if (form.type === 'number') {
      if (!isValidPhoneNumber(form.phoneNo)) {
        hasError = true;
        err.phoneNo = 'Enter a valid phone number';
      }
      if (!form.phoneNo) {
        hasError = true;
        err.phoneNo = 'This field is required';
      }
    } else if (form.type === 'upi') {
      if (!form.upi) {
        hasError = true;
        err.upi = 'This field is required';
      } else if (!isValidUpiId(form.upi)) {
        hasError = true;
        err.upi = 'Enter a valid UPI Id';
      }
    } else if (form.type === 'sms') {
      if (!form.phoneNo) {
        hasError = true;
        err.phoneNo = 'This field is required';
      } else if (!form.description) {
        hasError = true;
        err.description = 'This field is required';
      }
    } else if (form.type === 'website') {
      if (!form.website) {
        hasError = true;
        err.website = 'This field is required';
      }
    }

    if (!form.proof) {
      if (!form.description) {
        hasError = true;
        err.description = 'This field is required';
      }
    }

    if (hasError) {
      setErrors(err);
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateSaveData();
    if (isValid) {
      setBtnDisable(true);
      setIsLoading(true);
      let uniqueId = null;
      if (form.type === 'number') {
        uniqueId = form.phoneNo;
      } else if (form.type === 'upi') {
        uniqueId = form.upi;
      } else if (form.type === 'sms') {
        uniqueId = form.phoneNo;
      } else if (form.website) {
        uniqueId = form.website;
      }
      const postObj = {
        uniqueId,
        type: form.type,
        detail: form.description,
        articleSlug: form.articleSlug, // Include the article title in the post data
      };
      const postData = new FormData();
      postData.append('proof', form.proof);
      postData.append('json', JSON.stringify(postObj));

      dispatch(reportScammer(postData)).then((resp) => {
        setIsLoading(false);
        if (resp.data.success) {
          toast.success(resp.data.message, {
            position: 'top-left',
          });
          navigate(`/`);
        } else {
          setTimeout(() => {
            setBtnDisable(false);
          }, 3500);
          toast.error(resp.data.message, {
            position: 'top-left',
          });
        }
      });
    }
  };

  return (
    <div
      className="fab-scam-reg-card-container"
      id="fab-scam-reg-card-container"
    >
      {isLoading && <Loader parentNode="fab-scam-reg-card-container" />}
      <div className="fab-scam-reg-card">
        <div className="fab-scam-reg-card-top-section">
          <div className="fab-page-heading blue-color">Report Scam</div>
          <img
            src={CloseBtnIcon}
            alt="close-btn"
            className="fab-cursor-pointer"
            onClick={() => navigate('/home')}
          />
        </div>
        <div className="fab-scam-reg-card-warning">
          <img src={WarningIcon} alt="warning-icon" />
          <div className="fab-scam-reg-card-warning-text">
            Ensure that the information you are filling out is accurate.
          </div>
        </div>
        <div className="fab-form-control">
          <Dropdown
            label="Select Type"
            placeholder="Select Type"
            options={SELECT_TYPE_OPTIONS}
            selectedValue={selectedOption}
            onChange={(val) => handleSelectedOption(val)}
            disabled={dropDownDisabled}
            height={50}
          />
        </div>

        {form?.type === 'number' && (
          <div className="fab-form-control">
            <div className="fab-form-label">Phone number of scammer</div>
            <PhoneNumberInput
              placeholder="01234 56789"
              value={form.phoneNo}
              name="phoneNo"
              onChange={(e) => handlePhoneNoChange(e.target.value)}
              disabled={disabledField}
            />
            <ErrorText error={errors.phoneNo} errorClass="w3-center" />
          </div>
        )}
        {form?.type === 'upi' && (
          <div className="fab-form-control">
            <div className="fab-form-label">UPI id of scammer</div>
            <TextInput
              inputclass="fab-upi-input-text"
              placeholder="Enter UPI id (ex.1234567890@upi)"
              value={form.upi}
              name="upi"
              onChange={(val) => handleChange('upi', val)}
              disabled={disabledField}
            />
            <ErrorText error={errors.upi} errorClass="w3-center" />
          </div>
        )}
        {form?.type === 'sms' && (
          <div className="fab-form-control">
            <div className="fab-form-label">Phone number</div>
            <PhoneNumberInput
              placeholder="01234 56789"
              value={form.phoneNo}
              name="phoneNo"
              onChange={(e) => handlePhoneNoChange(e.target.value)}
              disabled={disabledField}
            />
            <ErrorText error={errors.phoneNo} errorClass="w3-center" />
          </div>
        )}
        {form?.type === 'website' && (
          <div className="fab-form-control">
            <div className="fab-form-label">Web URL of scammer</div>
            <TextInput
              inputclass="fab-upi-input-text"
              placeholder="Enter the URL (ex.http://scammer.url.com)"
              value={form.website}
              name="website"
              onChange={(val) => handleChange('website', val)}
              disabled={disabledField}
            />
            <ErrorText error={errors.website} errorClass="w3-center" />
          </div>
        )}
        <div className="fab-form-control">
          <div className="fab-form-label">
            {form?.type === 'sms' ? 'SMS/Email' : 'Description'}
          </div>
          <div className="fab-textarea-container fab-input-container">
            <textarea
              className={`fab-input ${
                !form.description ? 'fab-input-placeholder' : 'fab-input-active'
              }`}
              name="description"
              placeholder={
                form?.type === 'sms'
                  ? 'SMS/Email Content'
                  : 'Provide the detail about scam'
              }
              value={form.description}
              onChange={(e) => handleChange('description', e.target.value)}
            />
          </div>
          <ErrorText error={errors.description} errorClass="w3-center" />
        </div>

        {/* Add the new Dropdown for Articles */}
        <div className="fab-form-control">
          <SearchableDropdown
            label="Select an Article"
            placeholder="Select a matching Article"
            options={articleTitleOptions}
            selectedValue={selectedArticle}
            onChange={(val) => handleArticleTitleOption(val)}
            height={50}
          />
        </div>

        {selectedScamArticle?.length > 0 && (
          <BlogPostCard
            key={selectedScamArticle[0].slug}
            item={selectedScamArticle[0]}
          />
        )}

        {/* File upload and submit button */}
        <div className="fab-form-control">
          <div className="fab-form-label">Choose File </div>
          <label className="fab-file-card-container">
            {form.proof ? (
              <div className="fab-file-card-selected-file">
                <img src={getFileTypeIcon()} alt="file-type-pic" />
                <div className="fab-file-card-selected-file-name">
                  {form.proof?.name}
                </div>
              </div>
            ) : (
              <div className="fab-file-card-choose-file">
                <div className="fab-file-card-choose-file-text-section">
                  <img src={FileUploadIcon} alt="file-upload-icon" />
                  <div className="fab-file-card-sub-text-main">Select File</div>
                </div>
                <div className="fab-file-card-sub-text">
                  (Format: Images, Video, Audio &amp; PDF files)
                </div>
              </div>
            )}
            <input
              multiple
              name="proof"
              type="file"
              onChange={(e) => handleFileUpload(e)}
              accept="audio/*,video/*,image/*,.pdf"
            />
          </label>
          <ErrorText error={errors.proof} errorClass="w3-center" />
        </div>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
          buttontext="Submit"
          disabled={btnDisable}
          customclass={`primary-btn ${btnDisable ? 'fab-btn-disabled' : ''}`}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
};

export default Report;
