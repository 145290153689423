import React, { useState, useEffect } from 'react';
import './scam.scss';
import { getMyReports, getSpammerListByType } from '../../js/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import TextInput from '../common/TextInput';
import { removeNonValueFilters } from '../../js/utils';
import rightArrow from '../../images/icons/right_arrow.svg';
import { SELECT_STATUS_OPTIONS, SELECT_TYPE_OPTIONS } from '../../js/constants';
import Dropdown from '../common/Dropdown';
import moment from 'moment';
import Loader from '../common/Loader';
import searchIcon from './../../images/icons/search-icon.svg';
import useAuth from '../../Routes/useAuth';

const ReportList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const { spammerList } = globalState;
  const [reports, setReports] = useState([]);
  const [results, setResults] = useState(0);
  const [queryParams, setQueryParams] = useSearchParams({});
  const existingParams = Object.fromEntries([...queryParams]);
  const [isLoading, setIsLoading] = useState(false);
  const [listType, setListType] = useState(
    queryParams.get('reportType') === 'my-report' ? 'my-list' : 'main-list'
  );;

  const {
    auth: { isAuthenticated },
  } = useAuth();

  useEffect(() => {
    const type = queryParams.get('type') || 'number';
    const reportType = queryParams.get('reportType') || 'all';
    const status = queryParams.get('status') || 'ACTIVE';

    let params = { type, status, reportType };
    setQueryParams(params);
  }, [listType]);

  useEffect(() => {
    getReports();
  }, [queryParams]);

  const getReports = () => {
    setIsLoading(true);
    setReports([]);
    setResults(0);

    const action =
      listType === 'my-list' || queryParams.get('reportType') === 'my-report'
        ? getMyReports
        : getSpammerListByType;
    dispatch(
      action({
        type: existingParams?.type,
        status: existingParams?.status,
        search: existingParams?.search,
        reportType:
          listType === 'my-list' ? undefined : existingParams?.reportType,
      }),
    ).then((resp) => {
      setIsLoading(false);
      const respData = resp?.data;
      if (respData?.success && respData?.data?.length > 0) {
        let data = respData.data.map((ele) => ({
          ...ele,
          lastReportedAt: moment(ele.lastReportedAt).format('LLL'),
        }));
        setResults(respData.total);
        setReports(data);
      }
    });
  };

  const updateQueryParams = (params) => {
    const nParams = removeNonValueFilters({ ...existingParams, ...params });
    setQueryParams(nParams);
  };

  const handleMyReports = () => {
    setListType('my-list');
    setQueryParams({
      type: existingParams?.type,
      status: existingParams?.status,
      reportType: 'my-report',
    });
  };

  let viewContent;
  if (spammerList?.isFetching || isLoading) {
    viewContent = <Loader parentNode="fab-report-list-container" />;
  } else if (reports.length > 0) {
    viewContent = reports
      .filter((item) => item.type === existingParams?.type)
      .map((report) => (
        <div
          key={`report-${report.id}`}
          className="fab-report-list-card fab-cursor-pointer"
          onClick={() =>
            navigate(`/reports/detail/${report.id}`, { state: { report } })
          }
        >
          <div className="fab-report-list-card-inner">
            <div className="w3-left">
              <div className="fab-report-list-card-title">
                {report.encryptedNumber}
              </div>
              <div className="fab-report-list-card-sub-title">
                Last reported at{' '}
                <span className="fab-report-list-card-sub-desc">
                  {report.lastReportedAt}
                </span>
              </div>
              <div className="fab-report-list-card-sub-title">
                Report count{' '}
                <span className="fab-report-list-card-sub-desc">
                  {report.reportCount}
                </span>
              </div>
              <div className="fab-report-list-card-sub-title flex-container">
                <div
                  className={`fab-report-list-card-status ${report.status.toLowerCase()}`}
                >
                  {report.status}
                </div>
              </div>
            </div>
            <div className="fab-report-list-card-btn-section">
              <span className="fab-cursor-pointer w3-right">
                <img src={rightArrow} alt="view-button" />
              </span>
            </div>
          </div>
        </div>
      ));
  } else {
    viewContent = (
      <div className="fab-no-data w3-center">
        Scam reports not found on{' '}
        {existingParams?.type === 'number' ? 'Phone' : existingParams?.type} in
        status - {existingParams?.status}
      </div>
    );
  }

  return (
    <div className="fab-report-list-container" id="fab-report-list-container">
      <div className="flex flex-col w3-margin-bottom">
        <div className="fab-scam-top-section">
          <div className="fab-page-heading blue-color">
            {listType === 'my-list'
              ? 'My Reports'
              : existingParams?.reportType === 'recent'
              ? 'Recently Reported'
              : existingParams?.reportType === 'most'
              ? 'Most Reported'
              : 'All Reports'}
          </div>
          <Dropdown
            customCls="fab-rl-drop-down"
            placeholder="Select Type"
            options={SELECT_TYPE_OPTIONS}
            selectedValue={SELECT_TYPE_OPTIONS.find(
              (sItem) => sItem.key === existingParams?.type,
            )}
            onChange={(val) => updateQueryParams({ type: val.key })}
            height={30}
          />
        </div>
        <div className="flex-container align-items-center justify-content-space-between">
          <div className="w3-block fab-search-container">
            <TextInput
              inputclass="fab-search-box"
              placeholder="Search Report"
              value={queryParams.get('search') || ''}
              name="searchTerm"
              onChange={(val) => updateQueryParams({ search: val })}
            />
            <span className="fab-search-icon fab-cursor-pointer">
              <img
                className="fab-dropdown-icon"
                src={searchIcon}
                alt="search-icon"
              />
            </span>
          </div>
          <Dropdown
            placeholder="Status"
            options={SELECT_STATUS_OPTIONS}
            selectedValue={SELECT_STATUS_OPTIONS.find(
              (sItem) => sItem.key === existingParams?.status,
            )}
            onChange={(val) => updateQueryParams({ status: val.key })}
            height={30}
            customCls="w3-margin-left fab-rl-drop-down"
          />
        </div>
      </div>

      {(existingParams?.reportType || listType === 'my-list') && (
        <div className="fab-btn-group">
          {['all', 'recent', 'most'].map((type) => (
            <div
              key={type}
              className={`fab-btn-grp-btn-text fab-cursor-pointer ${
                existingParams?.reportType === type
                  ? 'fab-btn-grp-active-btn fab-btn-grp-active-btn-text'
                  : ''
              }`}
              onClick={() => {
                updateQueryParams({ reportType: type });
                setListType('main-list');
              }}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </div>
          ))}
          {isAuthenticated && (
            <div
              key="my-report"
              className={`fab-btn-grp-btn-text fab-cursor-pointer ${
                existingParams?.reportType === 'my-report'
                  ? 'fab-btn-grp-active-btn fab-btn-grp-active-btn-text'
                  : ''
              }`}
              onClick={handleMyReports}
            >
              My Reports
            </div>
          )}
        </div>
      )}
      <div className="flex-container">{`${results} results`}</div>
      <div style={{ minHeight: '65vh' }}>{viewContent}</div>
    </div>
  );
};

export default ReportList;
