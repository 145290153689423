import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Home from '../../Home';
import layoutConfig from '../../../Routes/layoutConfig';

const AppLayout = () => {
  const location = useLocation();
  const hideSidebar = layoutConfig.hideSidebar.includes(location.pathname);

  return (
    <div className="w3-row" style={{ background: '#f6faff' }}>
      {/* Static Home component */}
      <div className={`w3-col m5 l4 ${!hideSidebar ? 'w3-hide-small' : ''}`}>
        <Home />
      </div>
      {/* Dynamic route contents */}
      <div className={`w3-col m7 l8 ${hideSidebar ? 'w3-hide-small' : ''}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default AppLayout;
