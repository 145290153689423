import React from 'react';
import { useNavigate } from 'react-router-dom';
import './tabs.scss';

export default function Tabs({ tabs, activeTab, onTabChange }) {
  const navigate = useNavigate();

  const handleTabClick = (tabValue, route) => {
    if (activeTab !== tabValue) {
      onTabChange(tabValue); // This will update the parent state and trigger a re-render
      if (route) {
        navigate(route); // Navigate to the route if defined
      }
    }
  };

  return (
    <div className="tabs-container">
      <div className="tabs-btn-group-container">
        <div className="fab-btn-group">
          {tabs.map(({ label, value, route }) => (
            <div
              key={value}
              className={`fab-btn-grp-btn-text fab-cursor-pointer 
                ${value === 'ReportScam' && activeTab === 'ReportScam' ? 'highlight' : ''} 
                ${activeTab === value ? 'fab-btn-grp-active-btn fab-btn-grp-active-btn-text' : ''}`}              
              onClick={() => handleTabClick(value, route)}
              role="tab"
              aria-selected={activeTab === value}
            >
              {label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
